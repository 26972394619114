import Vue from 'vue';

const ApiService = {
    api_url: "",
    init(api_url, csrf_token = "") {
        this.api_url = api_url;
        Vue.axios.defaults.headers.common['X-CSRFToken'] = csrf_token

    },

    query(resource, params) {
        return this.doRequest(
            'get',
            `${this.api_url + resource}/`,
            params
        );
    },

    query2(resource, params) {
        return this.doRequest(
            'get',
            `${this.api_url + resource}`,
            params
        );
    },

    get(resource, slug = "", params = {}) {
        return this.doRequest(
            'get',
            `${this.api_url + resource}/${slug}`,
            params
        );
    },

    post(resource, params, config = {}) {
        return Vue.axios.post(`${this.api_url + resource + '/'}`, params, config)
    },

    update(resource, slug, params) {
        return Vue.axios.patch(`${this.api_url + resource}/${slug}`, params);
    },

    put(resource, params) {
        return Vue.axios.put(`${this.api_url + resource}`, params);
    },
    delete(resource, slug = "") {
        let slugPart = slug.length ? `/${slug}` : ''
        return Vue.axios.delete(`${this.api_url + resource + slugPart}`);
    },

    doRequest(action, url, params) {
        return new Promise((resolve, reject) => {
            Vue.axios[action](url, {params: params})
                .then(response => resolve(this.buildResult(response)))
                .catch(error => {
                    reject(error)
                })
        });
    },

    buildResult(response) {
        const result = response.data
        result.count = result.count ? result.count : 0;
        result.next = result.next ? result.next : null;
        result.prev = result.previous ? result.previous : null;
        return result;
    }
};

export default ApiService;

export const ThemeVersionsService = {
    get_resource(type) {
        if (type) {
            return `theme_versions/${type}`
        }
        return 'theme_versions'
    },

    query(params, type = null) {
        return ApiService.query(this.get_resource(type), params);
    },
    get(id, params) {
        return ApiService.get("theme_versions", id, params);
    },
    getThemes(id, params) {
        return ApiService.get("themes", id, params);
    },
    create(params, type) {
        return ApiService.post(this.get_resource(type), params);
    },
    update(id, params) {
        return ApiService.update("theme_versions", id, params);
    },
    destroy(id,) {
        return ApiService.delete("theme_versions" + `/${id}`);
    },
    bulkDeleteThemeVersions(params) {
        return ApiService.post(`theme_versions_bulk_delete`, params);
    },
    get_themes_previews(website_name, params) {
        return ApiService.query(`${website_name}/theme_versions`, params);
    },
    get_library_themes_previews(params) {
        return ApiService.query(`theme_versions`, { ...params, is_library: true });
    },
    getLatestVersion(website_name, themeId) {
    const queryMethod = website_name ? this.get_themes_previews.bind(this, website_name) : this.get_library_themes_previews.bind(this);
    return queryMethod({
            theme_id: themeId,
            ordering: '-version',
            latest_version_only: true,
            page_size: 1
        })
    },
    patchTheme(themeId, name, description) {
        return ApiService.update("themes", themeId + '/', {
            name: name,
        });
    },
    publishThemeVersion(themeVersionId, params) {
        return ApiService.update(`theme_versions/${themeVersionId}/publish`,'', params);
    }
};


export const AdsService = {
    init(website) {
        this.get_resource = (type) => {
            let path = [website, 'displayads'];
            if (type) {
                path.push(type);
            }
            return path.join('/');
        };
        return this;
    },
    query(params, type) {
        return ApiService.query(this.get_resource(type), params);
    },
    get(id, params, type) {
        return ApiService.get(this.get_resource(type), id, params);
    },
    create(params, type) {
        return ApiService.post(this.get_resource(type), params);
    },
    update(id, params, type) {
        return ApiService.update(this.get_resource(type), id, params);
    },
    destroy(id, type) {
        return ApiService.delete(this.get_resource(type) + `/${id}`);
    }
};


export const BulkAdsService = {
    init(website) {
        this.get_resource = (type) => {
            let path = [website, 'ads_bulk'];
            if (type) {
                path.push(type);
            }
            return path.join('/');
        };
        return this;
    },
    post(params, type) {
        return ApiService.post(this.get_resource(type), params);
    },
};


export const WebsitesService = {
    query(params) {
        return ApiService.query("websites", params);
    },
    get(id, params = {}) {
        return ApiService.get("websites", id, params);
    },
    create(params, config = {}) {
        return ApiService.post("websites", params, config);
    },
    update(id, params) {
        return ApiService.update("websites", id, params);
    },
    archive(id) {
        return ApiService.post(`websites/${id}/archive`);
    },
    unarchive(id) {
        return ApiService.post(`websites/${id}/unarchive`);
    },
    getPixelStatus(id) {
        return ApiService.get("websites", id + '/check_pixel_data_exist/');
    },
    getWebsiteAccountPermissions(name){
        return ApiService.get("websites", name + '/account_permissions/');
    },
    getAccountPermissions(accountId){
        return ApiService.get("accounts", accountId + '/permissions/');
    }
};

export const ItemSetService = {
    init(website) {
        this.get_path = () => {
            let path = [website, 'itemsets'];
            return path.join('/');
        };
        return this;
    },
    query(params) {
        return ApiService.query(this.get_path(), params);
    },
    get(id, params) {
        return ApiService.get(this.get_path(), id, params);
    },
    create(params) {
        return ApiService.post(this.get_path(), params);
    },
    update(id, params) {
        return ApiService.update(this.get_path(), id, params);
    },
    destroy(id) {
        return ApiService.delete(this.get_path(), `${id}`);
    }
};

export const AssetsService = {
    init(website) {
        this.get_path = () => {
            let path = [website, 'assets'];
            return path.join('/');
        };
        return this;
    },
    // use for all or pass params
    query(params) {
        return ApiService.query(this.get_path(), params);
    },
    get(id, params) {
        return ApiService.get(this.get_path(), id, params);
    },
    create(params, config = {}) {
        return ApiService.post(this.get_path(), params, config);
    },
    update(id, params) {
        return ApiService.update(this.get_path(), id + '/', params);
    },
    destroy(id) {
        return ApiService.delete(this.get_path() + `/${id}`);
    }
};

export const AssetGroupService = {
    init(website) {
        this.get_path = () => {
            let path = [website, 'asset_groups'];
            return path.join('/');
        };
        return this;
    },
    get(id, params) {
        return ApiService.get(this.get_path(), id, params);
    },
    query(params) {
        return ApiService.query(this.get_path(), params);
    },
    create(params, config = {}) {
        return ApiService.post(this.get_path(), params, config);
    },
};

export const ItemService = {
    init(website) {
        this.get_path = () => {
            let path = [website, 'item'];
            return path.join('/');
        };
        return this;
    },
    query(params) {
        return ApiService.query(this.get_path(), params);
    },
    get(id) {
        return ApiService.get(this.get_path(), id);
    },
    update(id, params) {
        return ApiService.update(this.get_path(), id, params);
    }
};

export const ReportService = {
    query(params, rtype) {
        return ApiService.query2(`reports/${rtype}`, params)
    },
    //NOTE: pass the encoded website id in the params
    get(rtype, id) {
        let path = [rtype, id].join('/')
        return ApiService.get("reports", path);
    },
};


export const ImportService = {
    get(id) {
        return ApiService.get("imports", id);
    },
    query(params) {
        return ApiService.query("imports", params);
    }
}


export const UserService = {
    create(resource, params) {
        return ApiService.post(resource, params);
    },
    get(id) {
        return ApiService.get("users/" + id);
    },
    updateSetting(id, params) {
        return ApiService.update("users/" + id + "/setting", "", params);
    },
};

export const VideoThemeFormService = {
    init(website) {
        this.get_path = (theme_path) => {
            let path = [website, theme_path];
            return path.join('/');
        };
        return this;
    },
    get(id) {
        return ApiService.get(this.get_path('video_theme_form'), id);
    },
    get_form_from_ad(ad_id) {
        return ApiService.get(this.get_path('video_theme_form_from_ad'), ad_id);
    }
};

export const ConversionTypesService = {
    init(website) {
        this.get_path = () => {
            let path = [website, 'conversion'];
            return path.join('/');
        };
        return this;
    },
    query(params) {
        return ApiService.query(this.get_path(), params);
    },
    get(id) {
        return ApiService.get(this.get_path(), id);
    },
    create(params) {
        return ApiService.post(this.get_path(), params);
    },
    update(id, params) {
        return ApiService.update(this.get_path(), id + '/', params);
    },
    destroy(id) {
        return ApiService.delete(this.get_path() + `/${id}`);
    }
};


export const EventTrackingService = {
    query(params) {
        return ApiService.query("tracking_event", params);
    },
    create(params, config = {}) {
        return ApiService.post("tracking_event", params, config);
    },
};

export const TiersService = {
    getAllPlans() {
        return ApiService.query("tiers");
    },
    getAllAddons(){
        return ApiService.query("addons");
    }
};

export const AccountsService = {
    query(id) {
        return ApiService.query("accounts"+ `/${id}`);
    },
};

export const BillingService = {
    checkout(params) {
        return ApiService.post("billing/checkout", params);
    },
    customer_portal(params) {
        return ApiService.post("billing/customer-portal", params);
    },
    plan_cancel(params) {
        return ApiService.post("billing/plan-cancel", params);
    },
    selected_addon(params) {
        return ApiService.post("billing/selected-addon", params);
    }
};