import { AdvertiserMenu } from "./AdvertiserMenu";
import { usePermissionsStore } from './store/PermissionStore.js';
import { mapState } from 'pinia';
import {TierUpgradeButton} from "./tiers/TierUpgradeButton";

let NavBar = {
    name: "nav-bar",
    props: {
        advertiserMenuProps: Object,
        websiteMenuProps: Object,
        accountMenuProps: Object,
        userMenuProps: Object,
        // Basic
        websiteId: String,
        accountId: String,
        staticUrl: String,
        isAuthenticated: String,
        viewName: String,
        hasShopifyTesterRole: Boolean,
        hasFacebookTesterRole: Boolean,
        hasAdminRole: Boolean,
        hasHarvesterRole: Boolean,
        store: Object
    },
    data: function () {
        return ({
            viewNames: {

            },
            permissionsStore : usePermissionsStore(),
            modalKey: "UpgradeYourPlan"
        })
    },
    components: {
        'advertiser-menu': AdvertiserMenu,
        TierUpgradeButton
    },
    methods: {
        activeClass(liViewName) {
            // Compare the list view name is the current (this.viewName) view
            // Refer to website.html
            if (this.viewName === liViewName) {
                return 'active'
            }
            return ''
        }
    },
    computed: {
        ...mapState(usePermissionsStore, ['permissions']),
        logoHref() {
            //TODO url move to base.html
            return this.isAuthenticated == 'True' ? '/' : 'https://adretriever.com'
        },
    },
    // truncate strings if too long
    filters: {
        truncatechars: function (data, num) {
            return data.length > num ? data.substring(0, num) + '...' : data;
        },
        decodeHTMLEntities: function (text) {
            const textArea = document.createElement('textarea');
            textArea.innerHTML = text;
            return textArea.value;
}
    },
    template:
        `
        <div class="fixed-top mint-background">
            <div class="container">
                <nav class="navbar navbar-expand-md pl-0 pr-0 retriever-top-nav">
                    <a :href="logoHref"><img class="img-responsive adretriever-logo" :src="staticUrl + 'img/adretriever-logo.svg'" alt="AdRetriever Logo" /></a>

                    <div class="collapse navbar-collapse justify-content-end" id="main_nav">
                        <!-- Advertiser menu -->
                        <div v-if="websiteId" id="advertiser-menu-app" class="dropdown mb-0 mr-0 p-0 d-inline-block align-middle">
                            <advertiser-menu v-bind="advertiserMenuProps" :staticUrl="staticUrl" />
                        </div>
                        
                        <!-- Website menu -->
                        <ul v-if="websiteId" class="navbar-nav mr-auto mt-2 mt-lg-0">
                            <li :class="activeClass('frontend:items')" class="nav-item">
                                <a class="nav-link" :href="websiteMenuProps.itemsURL">Items</a>
                            </li>
                            <li :class="activeClass('frontend:itemset-list-view')" class="nav-item">
                                <a class="nav-link" :href="websiteMenuProps.setsURL">Sets</a>
                            </li>
                            <li :class="activeClass('frontend:displayads')" class="nav-item">
                                <a class="nav-link" :href="websiteMenuProps.displayadsURL">Ads</a>
                            </li>
                            <li v-if="!hasShopifyTesterRole && !hasFacebookTesterRole" :class="activeClass('frontend:campaign-list-view')" class="nav-item">
                                <a class="nav-link" :href="websiteMenuProps.campaignsURL">Campaigns</a>
                            </li>
                            <li v-if="hasAdminRole" :class="activeClass('frontend:website-reports-view')" class="nav-item">
                                <a class="nav-link" :href="websiteMenuProps.reportsURL">Reports</a>
                            </li>
                        </ul>
        
                        <div v-if="websiteId || accountId" class="border-medium border-right ml-4 mr-2 py-3 h-100"></div>
        
                        <!-- Account menu -->
                        <b-dropdown v-if="accountMenuProps?.isShow && (websiteId || accountId)" ref="accountDropdown" variant="link" toggle-class="border-0 py-0" menu-class="pt-0" class="account-dropdown" no-caret>
                            <template #button-content>
                                <button type="button" class="btn border-0 p-0" data-toggle="dropdown">
                                    <div class="dropdown-toggle">
                                        {{ accountMenuProps.accountName | decodeHTMLEntities | truncatechars(23) }}
                                    </div>
                                </button>
                            </template>
                            <b-dropdown-header tag="span" class="user-email dropdown-header text-light-custom read-only mb-0 text-left px-0">
                                {{ accountMenuProps.accountName | decodeHTMLEntities }}
                            </b-dropdown-header>
                            <b-dropdown-item  :href="accountMenuProps.accountURL">Account</b-dropdown-item>
                            <b-dropdown-item v-if="permissions.user_invite" :href="accountMenuProps.usersURL">Collaborators</b-dropdown-item>
                            <b-dropdown-item :href="accountMenuProps.billingURL">Billing</b-dropdown-item>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item :href="accountMenuProps.switchAccountsURL">Switch Accounts</b-dropdown-item>
                        </b-dropdown>
        
                        <!-- Collaborator menu -->
                        <b-dropdown v-if="websiteId || accountId" ref="userDropdown" variant="link" toggle-class="border-0 pl-0" menu-class="pt-0" class="user-dropdown" no-caret>
                            <template #button-content>
                                <button type="button" class="btn border-0 p-0" data-toggle="dropdown">
                                    <div class="username-dropdown-button rounded-circle d-table-cell">{{ userMenuProps.usernameInitial }}</div>
                                </button>
                            </template>
                            <p class="user-email dropdown-header text-light-custom mb-0">{{userMenuProps.userEmail}}</p>
                            <b-dropdown-item :href="userMenuProps.urls.userSettings">Collaborator Settings</b-dropdown-item>
                            <b-dropdown-group v-if="hasAdminRole">
                                <div class="dropdown-divider"></div>
                                <b-dropdown-item :href="userMenuProps.urls.audit">Audit</b-dropdown-item>
                                <b-dropdown-item :href="userMenuProps.urls.harvester">Harvester</b-dropdown-item>
                                <b-dropdown-item :href="userMenuProps.urls.places">Places</b-dropdown-item>
                            </b-dropdown-group>
        
                            <b-dropdown-item v-if="hasHarvesterRole" :href="userMenuProps.urls.harvester">Harvester</b-dropdown-item>
                            <b-dropdown-item v-if="userMenuProps.isSuperUser" :href="userMenuProps.urls.adminTools">Admin Tools</b-dropdown-item>
                            <div class="dropdown-divider"></div>
                            <b-dropdown-item :href="userMenuProps.urls.signOut">Sign Out</b-dropdown-item>
                        </b-dropdown>
                    </div>
                </nav>
            </div>
        </div>    
    `,
}

export { NavBar }
